/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import SharedLibs from 'wizarding-world-web-shared';
import ShopRowSkeleton from '@components/ShopRowSkeleton';
import IsOverAge from '@components/IsOverAge';
import Bugsnag from '@bugsnag/js';
import { useInView } from 'react-intersection-observer';
import { SHOPIFY_CART_LOCALSTORAGE_KEY } from '@constants/index';
import { useUserContext } from '@contexts/user-context';
import useCountry from '@hooks/useCountry';
import { trackEvent, segmentUserId } from '@utils/analytics';
import { STORE_PASSTHROUGH } from '@constants/routes';
import { STORE_URL } from '@utils/graphQueries';
import productBg from './imgs/product-bg.svg';
import s from './ShopRow.module.scss';

const propTypes = {
  shopData: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    theme: PropTypes.oneOf(['dark', 'light']).isRequired,
    shopifyItemId: PropTypes.string.isRequired,
  }),
  location: PropTypes.oneOf(['home', 'article']).isRequired,
  pageName: PropTypes.string,
};

const ShopRow = ({
  shopData = {
    title: '',
    description: '',
    theme: 'dark',
  },
  pageName = '',
  location,
}) => {
  const { title, description, shopifyItemId, theme } = shopData;
  const { api: wizardingWorldApi } = SharedLibs.services;
  const [checkoutUTMParams, setCheckoutUTMParams] = useState({});
  const [productUTMParams, setProductUTMParams] = useState({});
  const [shopUrl, setShopUrl] = useState('');
  const [multipassToken, setMultipassToken] = useState(null);
  const { loadingProfile, isLoggedIn, profile } = useUserContext();
  const { country, loading: loadingCountry } = useCountry('');
  const productBackgroundImage = theme === 'dark' ? 'none' : `url(${productBg})`;
  const discountedPriceColor = theme === 'dark' ? '#9FA1AD' : '#696B78';
  const [sectionRef, inView] = useInView({
    threshold: 0.0,
    triggerOnce: true,
  });

  const currency = country === 'US' ? '$' : '£';
  const moneyFormat = `${currency}{{amount}}`;

  const getMultipassToken = async () => {
    try {
      const { data } = await wizardingWorldApi().query({
        query: STORE_URL,
        variables: {
          path: '/',
        },
      });
      // Get the token from the URL
      const urlObject = new URL(data?.storeUrl?.url);
      const token = urlObject.pathname.split('/').pop();
      setMultipassToken(token);
    } catch (e) {
      Bugsnag.notify(e);
    }
  };

  const loadEmbedScript = () => {
    if (window.ShopifyBuy) {
      return;
    }
    const script = document.createElement('script');
    script.async = true;
    script.src = process.env.REACT_APP_SHOP_EMBED_SCRIPT_URL;
    script.type = 'module';
    document.getElementsByTagName('head')[0].appendChild(script);
  };

  useEffect(() => {
    const isCartEmpty = () => {
      const shopifyCartKey =
        process.env.REACT_APP_SHOPIFY_CART_LOCALSTORAGE_KEY || SHOPIFY_CART_LOCALSTORAGE_KEY;
      // eslint-disable-next-line consistent-return
      let cartEmpty = true;
      Object.keys(localStorage).forEach((key) => {
        // Cart localstorage keys end in ".checkoutId"
        if (key.includes(shopifyCartKey)) {
          cartEmpty = false;
        }
      });
      return cartEmpty;
    };

    if (!isLoggedIn || profile?.underage || (isCartEmpty() && !inView)) {
      return;
    }
    const url = `${STORE_PASSTHROUGH}&utm_source=wwd_web&utm_medium=${location}_module&utm_campaign=HPShop&utm_referrer_path=${window.location.pathname}`;
    setShopUrl(url);
    setCheckoutUTMParams({
      utm_source: 'wwd_web',
      utm_medium: 'shop_carousel',
      utm_campaign: 'HPShop',
      utm_referrer_path: window.location.pathname,
      ajs_uid: segmentUserId(),
    });
    const productUtmMedium = location === 'home' ? 'shop_carousel' : 'article_shop_carousel';
    setProductUTMParams({
      utm_medium: productUtmMedium,
    });
    getMultipassToken();
    loadEmbedScript();
  }, [isLoggedIn, profile, location, inView]);

  // Prevents Shop widget hanging on back button click when cached page is served
  const pageShow = (event) => {
    if (event.persisted && window.ShopifyBuy) {
      document.body.style.opacity = '0';
      window.location.reload();
    }
  };

  useEffect(() => {
    window.addEventListener('pageshow', pageShow);
    /* istanbul ignore next */
    return () => {
      window.removeEventListener('pageshow', pageShow);
    };
  }, []);

  const cssVars = {
    '--buy-button-component-text-font-family': 'Bluu Next',
    '--buy-button-component-button-font-family': 'Sofia Pro',
    '--buy-button-component-header-font-family': 'Sofia Pro',
    '--buy-button-component-price-font-family': 'Sofia Pro',
    '--buy-button-component-toggle-button-background-color': '#6B63B5',
    '--buy-button-component-product-card-width': '208px',
    '--buy-button-component-card-background': theme === 'dark' ? '#1C1E28' : 'transparent',
    '--buy-button-component-image-wrapper-background': theme === 'dark' ? '#242633' : 'transparent',
    '--buy-button-component-text-color': theme === 'dark' ? '#FFF' : '#10141B',
    '--buy-button-component-button-color': '#FFF',
    '--buy-button-component-button-color-hover': '#FFF',
    '--buy-button-component-carousel-actions-color': theme === 'dark' ? '#FFF' : '#10141B',
    '--buy-button-component-carousel-actions-border':
      theme === 'dark' ? 'thin solid white' : 'thin solid #10141B',
    '--buy-button-component-carousel-actions-hover-border-color':
      theme === 'dark' ? 'white' : '#10141B',
    '--buy-button-component-carousel-actions-hover-color': theme === 'dark' ? '#FFF' : '#10141B',
    '--buy-button-component-carousel-actions-hover-color-mobile':
      theme === 'dark' ? '#FFF' : '#10141B',
    '--buy-button-component-cart-text-color': '#FFF',
    '--buy-button-component-scroller-color': 'transparent',
    '--buy-button-component-text-accent-color': '#9FA1AD',
  };

  const customCSS = `
  @font-face {
    font-family: 'Bluu Next';
    src: url('/fonts/BluuNext-Bold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  } 
  @font-face {
    font-family: 'Mason Sans OT';
    src: url('/fonts/MasonSansBold.woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Sofia Pro';
    src: url('/fonts/sofia-pro/normal_normal_300.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Sofia Pro';
    src: url('/fonts/sofia-pro/normal_normal_400.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  .shopify-buy__btn  {
    background: linear-gradient(93deg, #59489F 6.03%, #463C9D 65.45%, #464AB2 105.12%);
    font-size: 12px;
    @media (max-width: 600px) {
      width: 230px;
      height: 48px;
    }
  }
  .shopify-buy__btn:hover  {
   background: linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%), linear-gradient(93deg, #59489F 6.03%, #463C9D 65.45%, #464AB2 105.12%);
  }
  .shopify-buy__btn[disabled] {
    background: linear-gradient(93deg, #59489F 6.03%, #463C9D 65.45%, #464AB2 105.12%);
    opacity: 0.4;
  }
  .shopify-buy__product {
    margin-left: 33px;
  }
  .shopify-buy__product .shopify-buy__product__title {
    font-size: 14px;
    font-weight: 400;
  }
  .shopify-buy__product .shopify-buy__product__actual-price {
    font-size: 11px;
  }
  .shopify-buy__product__compare-price {
    font-family: 'Sofia Pro';
    font-size: 11px !important;
    color: ${discountedPriceColor} !important;
  }
  .shopify-buy__option-select__select {
    font-size: 14px;
    font-family: 'Sofia Pro';
    padding: 2px 10px;
  }
  .shopify-buy__cart__title {
    font-family: 'Bluu Next';
    font-size: 24px;
    color: #d2aa6b;
  }
  .shopify-buy__quantity-decrement,
  .shopify-buy__quantity,
  .shopify-buy__quantity-increment {
    background: #1C1E28;
  }
  .shopify-buy__quantity-decrement:hover,
  .shopify-buy__quantity:hover,
  .shopify-buy__quantity-increment:hover {
    background: #10141B;
    border-color: #6b63b5;
  }
  .shopify-buy__product .shopify-buy__product-img-wrapper {
    background-image: ${productBackgroundImage};
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 208px;
    @media (max-width: 576px) {
      height: 360px;
    }
  }
  .shopify-buy__cart-item__full-price,
  .shopify-buy__cart-item__discount,
  .shopify-buy__cart-empty-text  {
    color: #FFF;
  }
  .shopify-buy__option-select-wrapper {
    background: transparent;
    max-width: 66px;
  }
  .shopify-buy__cart-scroll {
    @media (max-width: 576px) {
      padding: 70px 0 90px 0;
    }
  }
  // Cart mobile styles
  .shopify-buy__quantity-container {
    @media (max-width: 576px) {
      float: left;
    }
  }
  .shopify-buy__cart {
    @media (max-width: 576px) {
      width: 100%;
    }
  }
  @media only screen and (max-width: 576px)  {
    .shopify-buy-frame--cart {
      max-width: unset !important;
    }
  }
  `;

  const overrideConfig = {
    options: {
      cart: {
        popup: true,
      },
      window: {
        width: 1000,
        height: 1000,
      },
    },
  };

  if (isLoggedIn && !profile.underage && (loadingProfile || loadingCountry)) {
    return <ShopRowSkeleton type="multiple" theme={theme} shopData={shopData} shopLink />;
  }

  return (
    <IsOverAge>
      {isLoggedIn && (
        <div className={cx(s.shopRow, s[theme])} ref={sectionRef}>
          <div className={s.header}>
            <div className={s.left}>
              {title && <div className={s.title}>{title}</div>}
              {description && <div className={s.description}>{description}</div>}
            </div>
            <div className={s.right}>
              <div className={cx(s.shopLink, s.desktop)}>
                <a
                  href={shopUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    trackEvent('CTA Clicked', {
                      cta_text: 'Visit the Harry Potter Shop',
                      location: pageName,
                    });
                  }}
                >
                  Visit the Harry Potter Shop
                </a>
              </div>
            </div>
          </div>
          <buy-button-component
            idx="hpshop-carousel"
            multipassToken={multipassToken}
            productPath="/products/"
            collectionHandle={shopifyItemId}
            cssVars={JSON.stringify(cssVars)}
            customCSS={customCSS}
            moneyFormat={moneyFormat}
            checkoutUTMParams={JSON.stringify(checkoutUTMParams)}
            productUTMParamsOverride={JSON.stringify(productUTMParams)}
            overrideConfig={JSON.stringify(overrideConfig)}
          />
          <div className={cx(s.shopLink, s.mobile)}>
            <a
              href={shopUrl}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                trackEvent('CTA Clicked', {
                  cta_text: 'Visit the Harry Potter Shop',
                  location: pageName,
                });
              }}
            >
              Visit the Harry Potter Shop
            </a>
          </div>
        </div>
      )}
    </IsOverAge>
  );
};

ShopRow.propTypes = propTypes;
export default ShopRow;
